const IS_AUTHENTICATED_KEY = 'dmarcwise/authenticated';
const ORGANIZATION_ID_KEY = 'dmarcwise/organizationId';
const DOMAINS_VIEW_MODE_KEY = 'dmarcwise/domainsViewMode';

/*
 * Authentication
 */
export const isAuthenticated = () => {
	return localStorage.getItem(IS_AUTHENTICATED_KEY) === 'true';
};

export const setAuthenticated = (isAuthenticated: boolean) => {
	if (!isAuthenticated) {
		localStorage.removeItem(IS_AUTHENTICATED_KEY);
		return;
	}

	localStorage.setItem(IS_AUTHENTICATED_KEY, isAuthenticated.toString());
};

/*
 * Organization ID
 */
export const getLastUsedOrgId = () => {
	return localStorage.getItem(ORGANIZATION_ID_KEY);
};

export const setLastUsedOrganizationId = (organizationId: string) => {
	localStorage.setItem(ORGANIZATION_ID_KEY, organizationId);
};

export const removeLastUsedOrganizationId = () => {
	localStorage.removeItem(ORGANIZATION_ID_KEY);
};

/*
 * Domains view mode
 */
export const getDomainsViewMode = () => {
	let mode = localStorage.getItem(DOMAINS_VIEW_MODE_KEY);
	if (mode && mode != 'list' && mode != 'grid') {
		mode = null;
		removeDomainsViewMode();
	}
	return mode as 'list' | 'grid' | null;
};

export const setDomainsViewMode = (mode: 'grid' | 'list') => {
	localStorage.setItem(DOMAINS_VIEW_MODE_KEY, mode);
};

export const removeDomainsViewMode = () => {
	localStorage.removeItem(DOMAINS_VIEW_MODE_KEY);
};
